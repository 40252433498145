.navbar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px;
    z-index: 1;
    border-bottom: 6px solid #216b7a;
}

.navbar .logo h1{
    color: black;
}

#logoimg {
    width: auto;
    height: 40px;
    max-width: 100%;
    object-fit: contain; /* Ensure the image maintains its aspect ratio */
}

.logo a{
    text-decoration: none;
}

.logo a:hover{
    cursor: pointer;
}

.nav-links{
    display: flex;
    gap: 20px;
    align-items: center;
}

.nav-links a{
    color: black;
    text-decoration: none;
    font-size: 20px;
    transition: all 0.3s;
}

.nav-links a:hover{
    color : #00ae8e;
}

.hamburger{
    display: none;
}

@media screen and (max-width:600px) {
    .navbar{
        flex-direction: column;
    }

    .nav-links{
        flex-direction: column;
        margin-top: 10px;
        text-align: center;
    }

    .nav-links{
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s ;
    }

    .nav-links a{
        margin: 5px 0;
    }

    .logo h1{
        font-size: 28px;
    }

    .hamburger{
        display: block;
        cursor: pointer;
    }

    .logo{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .logo img{
        width: 30px;
        height: 30px;
    }

    .logo h1{
        margin-left: 10px;
    }
}
