.about-us{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    
    padding: 5%;
    margin-top: 15px;
}

.about-us-main{
   display: flex;
   flex-direction: row;
}

.about-us-content{
   width: 70%;
   
}

.about-img{
   margin-left: 100px;
   height: 50%;
   width: 50%;
}

.about-us-content h1{
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 50px;
    text-align: center;
    color: rgb(26, 31, 32);
}

.about-us-content p{
    font-size: 27px;
    margin-bottom: 10px;
    margin-left: 30px;
    font-weight: 400;
    color: rgb(29, 39, 149);
}

.Contact-us-content h1{
    margin-bottom: 10px;
    font-size: 25px;
    text-align: center;
    font-weight: 400;
}

span{
  font-weight: 600;
}

/* .social-icons-container img{
    height: 35px;
    width: 35px;
} */

.social-icons-container p{
  font-size: 12px;
}

.Contact-us-content{
  margin-top: 150px;
}

/*Responsive*/
@media screen and (max-width:600px) {

  .about-us{
    text-align: left;
  }

  .about-us-content h1{
    text-align: center;
    margin-bottom: 20px;
  }

  .about-us-content p{
    font-size: 16px;
  }

  .Contact-us-content{
    text-align: center;
    margin-bottom: 100px;
  }
}

@media (max-width: 1024px) {
  .about-us-main {
      flex-direction: column;
      align-items: center;
  }

  .about-img img{
      position: relative;
      top: 70px;
      right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      order: 1;
  }

  .about-us-content {
      width: 85%;
      order: 2;
  }

  .about-us-content h1 {
      font-size: 50px;
      position: relative;
      bottom: 350px;
  }

  .about-us-content p {
      font-size: 34px;
  }
}

@media (max-width:1300px ) {

  .about-img {
    width: 270px;
    height: auto;
    margin-bottom: 20px; 
    position: relative;
    top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .about-img img{
     width: 600px;
     height: 400px;
     margin-bottom: 40px;
     position: relative;
     top: 8s0px;
     right: 60px;
     display: flex;
     justify-content: center;
     align-items: center;
  }

  .about-us-content h1{
    position: relative;
    bottom: 480px;
  }
}

@media (max-width: 768px) {
  .about-us-content p {
      font-size: 22px;
      margin-left: 0;
  }

  .about-us-content h1 {
      font-size: 35px;
  }

  .about-img {
      width: 270px;
      height: auto;
      margin-bottom: 50px; 
      position: relative;
      top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
}

@media (max-width: 480px) {
  .about-us-content h1 {
      font-size: 30px;
      position: relative;
      bottom: 280px;
  }

  .about-us-content p {
      font-size: 18px;
  }

  .about-img img{
    height: 200px;
    width: 230px;
  }

}
