.gallery-container{
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.gallery-card{
    width: 24%;
    height: 150px; 
    padding: 10px;
    background-color: none;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.gallery-card-overlay{
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  transition: all 0.5s ease;
  padding: 10px;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.gallery-card:hover .gallery-card-overlay {
  top: 0;
  opacity: 1;
  cursor: pointer;
  pointer-events: all;
}

.gallery-card-overlay p{
  color: white;
}

.gallery-card:hover .text{
  opacity: 0;
}



@media screen and (max-width: 1000px) {
  .gallery-card{
    width: 33%;
  }  
}

@media screen and (max-width: 750px) {
  .gallery-card{
    width: 50%;
  }  
}

@media screen and (max-width: 550px) {
  .gallery-card{
    width: 100%;
  }  

  .gallery-container{
    padding: 20px;
  }

}

@media screen and (max-width: 400px) {

  .gallery-card h3{
    font-size: 16px;
  }

  .gallery-card p{
    font-size: 14px;
  }

   
}

