.footer-wave-svg {
    background-color: #216b7a;
    display: block;
    height: 40px;
    width: 100%;
}
.footer-wave-path {
    fill: #bbe0d2;
}

.footer{
    background-color: #216b7a;
    padding: 40px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.footer .about{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 2px solid #000000;
}

.footer .footer-logo a{
    color: #f2f2f2;
    font-size: 20px;
    text-decoration: none;
}

.footer p{
    color: #f2f2f2;
    font-size: 16px;
    font-weight: 100;
    line-height: 20px;
    text-align: center;
    letter-spacing: 1px;
    max-width: 500px;
    margin-bottom: 10px;
}

.contact{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.contact h2{
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 10px;
    color: white;
}

.links-container ul{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.links-container ul li{
    list-style: none;
}

.links-container ul li a img{
    width: 30px;
    height: 30px;
}


.footer-end{
    text-align: center;
    background-color: #0c333b;
}

.footer-end p{
    color:white;
}

