p , h1 , h2 , h3 {
    color: #092635;
}

.carousel{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.carousel-container{
    display: flex;   
    position: relative;
    transition: all 1s;
}

.carousel-button-conatiner button{
    font-size: 20px;
    font-weight: bold;
    width: 80px;
    height: 40px;
    color:white;
    margin-left: 20px;
    background-color:#6A4D8A;
    border: 1px solid #222f36;
    border-radius: 10%;
}

.carousel-button-conatiner button:hover{
    color: #222f36;
}

/* //// */

.carousel-content{
    position: relative;
    right: -33%;
    transition: all 0.4s;
    min-width: 100vw;
    max-width: 100vw;
    overflow: hidden;
}

/*  */

.intro{
    display: flex;
}

.hero-section h1{
    position: relative;
    top: 37px;
    margin-bottom: 30px;
}

.intro-text {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    gap: 10px;
}

.intro-text p {
    font-size: 25px;
    color: rgb(29, 39, 149);
}

.intro-img{
    max-height: 240px;
}

.intro-img img{
    height: 240px;
}

.content-1{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 100%;
    padding: 0% 2% 2% 5%;
    /* background-image: url("../assets/bg-4.png");
    background-repeat: no-repeat;
    background-size: cover; */
}

.content-1 h1{
    padding: 10px;
}

.options-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.options-btn{
    display: flex;
    justify-content: flex-end;
}

.card-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.option-card{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 280px;
    height: 150px;
    border: 1px solid black;
    background-color: #79797988;
    background-blend-mode: multiply;
    background-repeat: no-repeat;
    background-size: cover;
    transition: all .6s;
}

.option-card h3{
    color: white;
    font-size: 25px;
    transition: all .6s;
}

.option-card:hover{
    transform: scale(1.1);
    cursor: pointer;
}
.option-card:hover h3{
    font-size: 30px ;
}

.card-container:has(.option-card:hover) .option-card:not(:hover){
    transform: scale(0.9);
    opacity: 0.8;
}

.option-card.selectedoption{
    border: 4px solid black;
}

.card1{
    background-image: url("../assets/a.png");
}

.card2{
    background-image: url("../assets/p.png");
}

.card3{
    background-image: url("../assets/t.png");
}


button{
    background-color: #fff;
    border: 2px solid #0e3954;
    border-radius: 44px;
    color: #144b6d;
    font-size: 15px;
    font-weight: 400;
    margin: 5px;
    padding: 10px;
    transition: all .6s;
    width: 200px;
}
button:hover{
    background-color: #1b447bd9;
    cursor: pointer;
    color: white;
    border: 2px solid white;
}


/* page 2 */

.content-2{
    padding: 10px 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.content-header {
    text-align: center;
}

.field-container{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 10px 30px; 
}

.field-card{
    z-index: 10;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.5s;
    -webkit-transform: translateZ(0);
}

.field-card-body{
    width: 100%;
    height: 65px;
    text-align: center;
    border: 1px solid black;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s;
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
}


.field-card-bg {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -35px;
    right: -35px;
    border-radius: 50%;
    transition: all .5s;
    z-index: -1;
    -webkit-transform: translateZ(0);
}

.field-card.selectedField .field-card-bg{
    transform: scale(10);
}

.field-card.selectedField .field-card-body{
    border: 3px solid black;
}

.field-card.selectedField h3{
    color: rgba(255, 255, 255, 0.956);
}

.field-card h3{
    z-index: 1;
}

.field-card:hover{
    cursor: pointer;
    transform: scale(1.1);
}

.field-card:hover h3{
    color: white;
}

.field-card:hover .field-card-bg {
    transform: scale(10);
}

.field-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20%;
}

/*  */

.content-3{
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 30px 50px;
    gap: 20px;
}

.input-container{
    text-align: center;
    background-color: #1e7d90;
    border-radius: 10px;
    padding: 40px 56px;
}

.input-container p{
    color: rgb(255, 255, 255);
    margin-bottom: 10px;
}

textarea{
    border: 2px solid black;
    border-radius: 20px;
    margin: 5px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #bbe0d2;
    /* resize: none; */
}

textarea:focus{
    outline: none;
    border: 2px solid #144b6d;
}

.social-icons-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.social-icons-container img{
    height: 40px;
    width: 40px;
    transition: all .6s;
}

.social-icons-container p{
    font-size: 12px;
}

.social-icons-container img:hover{
    transform: scale(1.2);
    cursor: pointer;
}

.social-icons-container div{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* Media Quries */

@media screen and (max-width:900px) {
    .card-container{
        flex-direction: column;
    }

    .options-container{
        width: 100%;
        padding: 30px;
        text-align: center;
    }

    .card-container{
        width: 100%;
        gap: 20px;
    }

    .card{
        width: 100%;
    }

    .intro{
        flex-direction: column;
    }

    .intro-img{
        display: flex;
        justify-content: center;
        order: 1;
    }

    .intro-text{
        order: 2;
    }

    .intro .intro-img img{
        height: 200px;
    }

    .intro-text p{
        font-size: 18px;
        padding: 40px 20px;
        line-height: 30px;
    }

    .content-1{
        padding:0px;
    }

    .content-1 h1{
        padding: 15px;
        text-align: center;
    }

    .field-container{
        grid-template-columns: repeat(3, 1fr);
        width: 100%;
    }
    
}


@media screen and (max-width:650px) {
    

    .carousel-container{
        flex-direction: column;
        position: static;
        }

    .carousel-content{
        right:0px;
        padding: 10px;
    }

    .field-container{
        grid-template-columns: repeat(2, 1fr);
        width: 100%;
    }

    .content-2{
        padding: 20px;
    }

    .field-card-body{
        width: 100%;
        padding: 20px;
    }

    .field-card-body h3 {
            font-size: 16px;
    }

    .option-card{
        width: 100%;
    }

    .input-container {
        padding: 10px 20px;
    }

    .input-container textarea{
        width: 180px;
        height: 120px;
    }

    .options-btn{
        display: none;
    }

    .field-btn{
        display: none;
    }

    h1 , h2 {
        font-size: 22px;
    }

    /* cancel hover effects for mobile devices*/

    .field-card:hover .field-card-bg {
        transform: scale(1);
    }

    .field-card:hover h3{
        color: black;
    }

    .field-card:hover{
        transform: scale(1);
    }

    .option-card:hover{
        transform: scale(1);
    }
    
    .card-container:has(.option-card:hover) .option-card:not(:hover){
        transform: scale(1);
        opacity: 1;
    }

}



